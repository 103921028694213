import React, {useState} from "react"
import { StaticQuery, graphql, Link } from 'gatsby'
import { Location } from '@reach/router'
import queryString from 'query-string'

import Layout from "../components/layout"
import styled from "styled-components"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import Carousel from "../components/carousel"
const Pagination = styled.div`
  ul {
    display:flex;
    align-items: center;
    justify-content: center;
    li {
      display:block;
      list-style-type:none;
      margin:20px;
      width:40px;
      height:40px;
      padding:9px 0px 0px 0px;
      text-align:center;
      box-sizing:border-box;
      border-radius:20px;
      border:1px solid rgba(0,0,0,0.1);
    }
    li:hover,li.active {
      cursor:pointer;
      padding-top:8px;
          border:2px solid rgb(60, 230, 200);  
    }
  }

` 

const getArtworks = (oartists) => {
  let artists = [...oartists]
  console.log("artworks!", artists[1].artworks[0].title)
  artists[1].artworks.shift();
  console.log("artworks!", artists[1].artworks[0].title)
}
const createLinkURL = ({page,filter}) => {
  
  if(page && page > 1 && filter) {
  return `/artworks?page=${page}&filter=${filter}`
  }
  else if(page && page > 1) {
  return `/artworks?page=${page}`
  }
  else if(filter) {
  return `/artworks?filter=${filter}`    
  }
  else {
    return `/artworks?page=1&filter=all`
}
}
const getPagination = ({length,current_page = 1,filter}) => {

  let number_of_pages = Math.ceil(length / 15);
  let items = Array.from({length: number_of_pages}, (v, i) => <Link to={createLinkURL({filter,page:i + 1})}><li className={parseInt(current_page - 1) == parseInt(i)? "active": undefined}>{i + 1}</li></Link>);
  return items
}
const Artworks = ({tag,page}) => {
  
  
  let [currentFilter, setCurrentFilter] = useState(undefined);
      
  return <Location>
{({ location, navigate }) => {

  let query_string = queryString.parse(location.search)
  
  if(query_string.filter) {
    setCurrentFilter(query_string.filter);
  }  
  
  return <Layout hero={undefined} intro={false}>
    <SEO title="Home" />
    <div style={{ }}>
      
      <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"80px", textAlign:"center"}}>Available artworks</h1>
        <div className={"titles inline"}>
          <Link to={createLinkURL({})}><h3 className={currentFilter == undefined || currentFilter == "all" ? "active" : null} onClick={() => {setCurrentFilter(undefined)}}>All</h3></Link>
          <Link to={createLinkURL({filter:"sculpture"})}><h3 className={currentFilter == "sculpture" ? "active" : null}>Sculpture</h3></Link>
          <Link to={createLinkURL({filter:"painting"})}><h3 className={currentFilter == "painting" ? "active" : null} onClick={() => {setCurrentFilter("painting")}}>Paintings</h3></Link>
          <Link to={createLinkURL({filter:"on-paper"})}><h3 className={currentFilter == "on-paper" ? "active" : null} onClick={() => {setCurrentFilter("on-paper")}}>Works on paper</h3></Link>
        </div>

        <StaticQuery
        query={graphql`{
          artists:  craftAPI { entries(type: "artist") {
            title
            ... on CraftAPI_artist_artist_Entry {
              artworks {
                ... on CraftAPI_artwork_artwork_Entry {
                  id
                  slug
                  title
                  materials
                  sold
                  tags {
                    title
                  }
                  artist {
                    ... on CraftAPI_artist_artist_Entry {
                      id
                      slug
                      title
                      artworks(limit: 3) {
                        ... on CraftAPI_artwork_artwork_Entry {
                          id
                          images {
                            ... on CraftAPI_artworks_Asset {
                              id
                              img
                              small: url(width: 800)
                              filename
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  imageThumbnail {
                    ... on CraftAPI_artworks_Asset {
                      id
                      url
                      filename
                      medium: url(width: 1200)
                      slug
                      img
                    }
                  }
                  images {
                    ... on CraftAPI_artworks_Asset {
                      id
                      url
                      filename
                  small: url(width: 800)
                      slug
                      img
                    }
                  }
                }
              }
            }

          }}}`}
        render={
        (data) => {
          let page_offset = query_string.page ? (parseInt(query_string.page) - 1) * 15 : 0
          let artworks = data.artists.entries.reduce((accum,current) => {
            let a = current.artworks;
            if(Array.isArray(a)) {
                return [...accum, ...a]
            }
          return [...accum]
          }, []).filter((artwork) => {
  
            if(artwork.sold && artwork.sold.includes("sold")) {
              return false;
            }
            
            let tags = artwork.tags.map((t) => t.title);
            
            if(currentFilter == undefined || currentFilter == "all") {
            return true;
            }
            else if (tags.includes(currentFilter.replace("-"," ")))  {
              return true;
            }
          return false;  
          })
          
          let sorted_artworks = artworks.sort((entry,entryb) => {
          if(entry.title.substr(-2) < entryb.title.substr(3)){
            return -1
          }  else if(entry.title.substr(-2) > entryb.title.substr(3)) {
            return 1
          }
        return 0}).slice(page_offset,page_offset + 15).reduce((resultArray, item, index) => { 
  const chunkIndex = Math.floor(index/3)

  if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
  }

  resultArray[chunkIndex].push({
    type:"image", 
    link:"/" + item.slug,
    url:item.imageThumbnail?.[0]?.medium ?? item.images?.[0]?.small,
    details:<div><div className="title">{item.artist[0].title}</div><div className="materials">{item.title}</div><div className="dimensions">{item.materials}
  </div></div>
  })

  return resultArray
}, []).map((row) => <ImageRow images={row} forceLength={3} /> )


          return <div>{sorted_artworks}<Pagination>
                  <ul>
                {getPagination({current_page:query_string.page,length:artworks.length,filter:query_string.filter})}
              
                  </ul> 
                  
              </Pagination></div>
        } } />
   

          

    </div>
  </Layout>}}
</Location>
}

export default Artworks
